<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Reset Password v1 -->
      <b-card class="mb-0">
        <!-- Brand logo-->
        <b-link href="https://automatumcloud.com" class="brand-logo">
          <b-img
            :src="automatumlogo"
            height="64px"
            alt="Automatum Logo"
          ></b-img>
        </b-link>
        <!-- /Brand logo-->

        <b-card-title class="mb-1"> Reset Password 🔒 </b-card-title>
        <b-card-text class="mb-2">
          Your new password must be different from previously used passwords
        </b-card-text>

        <b-alert v-if="error" variant="danger" show>
          <div class="alert-body">
            <span>{{ error }}</span>
          </div>
        </b-alert>

        <!-- form -->
        <validation-observer ref="simpleRules" #default="{ invalid }">
          <b-form
            method="POST"
            class="auth-reset-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- email -->
            <b-form-group label="Email" label-for="login-email">
              <validation-provider
                #default="{ errors }"
                name="Email"
                vid="email"
                rules="required|email"
              >
                <b-form-input
                  id="login-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false : null"
                  name="login-email"
                  placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Resend code -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="confirmation-code">Code</label>
                <b-link @click="resendCode">
                  <small>Resend Code?</small>
                </b-link>
              </div>

              <validation-provider
                #default="{ errors }"
                name="Code"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="confirmation-code"
                    v-model="code"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="confirmation-code"
                    placeholder="Code"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- password -->
            <b-form-group label="New Password" label-for="reset-password-new">
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="Password"
                rules="required|password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="reset-password-new"
                    v-model="password"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    name="reset-password-new"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button block type="submit" variant="primary" :disabled="invalid">
              <div
                v-if="isLoading"
                class="d-flex align-items-center justify-content-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  class="spinner"
                  style="fill: white; transform: ; msfilter: "
                >
                  <path
                    d="M12 22c5.421 0 10-4.579 10-10h-2c0 4.337-3.663 8-8 8s-8-3.663-8-8c0-4.336 3.663-8 8-8V2C6.579 2 2 6.58 2 12c0 5.421 4.579 10 10 10z"
                  ></path>
                </svg>
                <div class="loader" style="margin-left: 3px">Processing...</div>
              </div>
              <div v-else>Set New Password</div>
            </b-button>
          </b-form>
        </validation-observer>

        <p class="text-center mt-2">
          <b-link :to="{ name: 'auth-login-v1' }">
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
        </p>
      </b-card>
      <!-- /Reset Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BCard,
  BCardTitle,
  BCardText,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BFormInput,
  BButton,
  BImg,
} from "bootstrap-vue";
import { required, email } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  resetPassword,
  sendCodeResetPassword,
} from "@/auth/amplify/amplifyService";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";

export default {
  components: {
    VuexyLogo,
    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BImg,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      code: "",
      userEmail: "",
      password: "",
      isLoading: false,
      error: null,
      // validation
      required,
      email,

      automatumlogo: require("@/assets/images/automatum/logo-1.png"),
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          try {
            this.isLoading = true;
            await resetPassword(this.userEmail, this.code, this.password);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Form Submitted",
                icon: "EditIcon",
                variant: "success",
              },
            });
            this.isLoading = false;
            this.$router.push("/login");
          } catch (error) {
            this.error = error.message;
            this.isLoading = false;
          }
        }
      });
    },
    async resendCode() {
      try {
        if (!this.userEmail)
          return (this.error =
            "Please provide your email so we can send you password reset code.");
        await sendCodeResetPassword(this.userEmail);
      } catch (error) {
        this.error = error.message;
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

.spinner {
  animation: spin 1s linear infinite;
}

.loader {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
